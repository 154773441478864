import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Text, Box, SimpleGrid, Heading, Button, Skeleton, Divider } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { getPublishedArticles, getComingSoonArticles, getArticleCategories } from "../Services/api";

const Articles = () => {
  const { categoryId } = useParams();
  const [articles, setArticles] = useState([]);
  const [comingSoon, setComingSoon] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const publishedArticlesResponse = await getPublishedArticles();
        const filteredPublishedArticles = publishedArticlesResponse.filter(
          (article) => article.category === categoryId
        );
        setArticles(filteredPublishedArticles);

        const comingSoonArticlesResponse = await getComingSoonArticles();
        const filteredComingSoonArticles = comingSoonArticlesResponse.filter(
          (article) => article.category === categoryId
        );
        setComingSoon(filteredComingSoonArticles);

        const categoriesResponse = await getArticleCategories();
        const categoryData = categoriesResponse.find(
          (cat) => cat.id === categoryId
        );
        setCategory(categoryData);
      } catch (error) {
        setError("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId]);

  const formatCategoryType = (type) => {
    return type
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  if (loading) {
    return (
      <Box p={5}>
        <Skeleton height="40px" mb={6} />
        <SimpleGrid columns={[1, 2, 3]} spacing={10}>
          {[...Array(6)].map((_, index) => (
            <Skeleton key={index} height="400px" borderRadius="xl" />
          ))}
        </SimpleGrid>
      </Box>
    );
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Box p={5}>
      <Heading as="h1" size="2xl" mb={8}>
        {category ? `${formatCategoryType(category.type)} Businesses` : "Articles"}
      </Heading>

      <SimpleGrid columns={[1, null, 2, 3]} spacing={10}>
        {articles.map((article) => (
          <Box
            key={article.id}
            as={Link}
            to={`/categories/${categoryId}/articles/${article.id}`}
            borderRadius="xl"
            overflow="hidden"
            height="400px"
            display="flex"
            bg="white"
            flexDirection="column"
            justifyContent="space-between"
            _hover={{
              bg: "white",
              boxShadow: "0 30px 50px rgba(0, 0, 0, 0.15)",
              transform: "translateY(-10px)",
            }}
            transition="all 0.3s"
          >
            <Box position="relative">
              {article.articleThumbnail ? (
                <img
                  src={article.articleThumbnail}
                  alt={article.articleTitle}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Box
                  bg="gray.200"
                  width="100%"
                  height="200px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text>No Image</Text>
                </Box>
              )}
              <Text
                position="absolute"
                top="50%"
                right="10px"
                transform="translateY(-50%)"
                color="white"
                fontWeight="bold"
                bg="rgba(0, 0, 0, 0.7)"
                px={2}
                py={1}
                borderRadius="md"
              >
                {article.articleTitle}
              </Text>
            </Box>
            <Box p={5} flex="1" display="flex" flexDirection="column" justifyContent="space-between">
              <Text mb={4} noOfLines={3} flex="1">
                {article.articleDescription || "No description available"}
              </Text>
              <Button
                colorScheme="teal"
                size="sm"
                rightIcon={<ArrowForwardIcon />}
                alignSelf="flex-start"
                _hover={{ bg: "#e63946" }}
                mt="auto"
              >
                View
              </Button>
            </Box>
          </Box>
        ))}
      </SimpleGrid>

      {comingSoon.length > 0 && (
        <>
          <Divider borderColor="gray.400" my={10} borderBottomWidth={2} />
          <Heading as="h2" size="lg" mt={10} mb={6}>
            Coming Soon:
          </Heading>
          <SimpleGrid columns={[1, 2, 3]} spacing={10}>
            {comingSoon.map((article) => (
              <Box
                key={article.id}
                borderRadius="xl"
                overflow="hidden"
                height="250px"
                display="flex"
                bg="gray"
                opacity={0.8}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                cursor="not-allowed"
                textAlign="center"
                p={5}
                _hover={{
                  boxShadow: "0 20px 30px rgba(0, 0, 0, 0.1)",
                }}
                transition="all 0.3s"
              >
                <Text fontWeight="bold" color="white" fontSize="lg" mb={2}>
                  {article.displayTitle}
                </Text>
                <Text color="white" fontStyle="italic" fontSize="sm">
                  Details coming soon
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};

export default Articles;
