import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import Header from "./Header";
import Navigation from "./Navigation";
import Footer from "./Footer"; // Import the Footer component

const LayoutOuter = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Header onSidebarOpen={onOpen} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent maxWidth={["240px", "300px"]}>
            <DrawerBody>
              <Navigation onClose={onClose} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {/* Main content container that grows to fill available space */}
      <Box flex="1" p={[2, 3, 4]} bg="white" borderRadius="lg" m={1} mb={10}>
        {children}
      </Box>

      <Footer />
    </Box>
  );
};

export default LayoutOuter;
