import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Flex, Link, Text, Button, Heading } from "@chakra-ui/react";
import ToolIcon from "../Components/LogoIcon";
import { useNavigate } from "react-router-dom";
import { getToolReviewDetail } from "../Services/api";

const toolNameMap = {
  "Audition": "Adobe Audition",
  // Add more mappings if necessary
};

const ToolsSection = ({ tools, articleTitle }) => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState({});

  // Memoize the 'tools' object to prevent unnecessary renders
  const memoizedTools = useMemo(() => tools, [tools]);

  const fetchReviews = useCallback(async () => {
    const fetchedReviews = {};
    for (const tool of Object.values(memoizedTools || {})) {
      const mappedName = toolNameMap[tool.Name] || tool.Name;
      try {
        const toolReview = await getToolReviewDetail(mappedName);

        if (toolReview && toolReview.review) {
          fetchedReviews[tool.Name] = toolReview.review;
        }
      } catch (error) {
        // Handle error (optional logging)
      }
    }

    // Only update state if reviews have actually changed
    if (JSON.stringify(fetchedReviews) !== JSON.stringify(reviews)) {
      setReviews(fetchedReviews);
    }
  }, [memoizedTools, reviews]);

  useEffect(() => {
    if (memoizedTools && Object.keys(memoizedTools).length > 0) {
      fetchReviews();
    }
  }, [memoizedTools, fetchReviews]);

  if (!memoizedTools || Object.keys(memoizedTools).length === 0) return <Text>No tools available for this article.</Text>;

  return (
    <>
      <Heading as="h3" size="lg" mb={4}>
        {articleTitle} Tools
      </Heading>
      <Table variant="simple" size="sm" width="100%">
        <Thead>
          <Tr>
            <Th fontSize="md" fontFamily="Poppins">Tool Name</Th>
            <Th fontSize="md" fontFamily="Poppins">Type</Th>
            <Th fontSize="md" fontFamily="Poppins">Description</Th>
            <Th fontSize="md" fontFamily="Poppins">Starting Price</Th>
            <Th fontSize="md" fontFamily="Poppins">See Review</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.values(memoizedTools || {})
            .sort((toolA, toolB) => {
              const priceA = toolA.StartingPrice.toLowerCase();
              const priceB = toolB.StartingPrice.toLowerCase();
              if (priceA === "free" || priceA === "£0") return -1;
              if (priceB === "free" || priceB === "£0") return 1;
              return 0;
            })
            .map((tool, index) => (
              <Tr key={index}>
                <Td>
                  <Flex alignItems="center">
                    <ToolIcon logo={tool.logo} altText={`${tool.Name} logo`} />
                    <Link href={tool.url} isExternal textDecoration="underline">
                      <Text ml={2}>{tool.Name}</Text>
                    </Link>
                  </Flex>
                </Td>
                <Td>{tool.type}</Td>
                <Td>{tool.Description}</Td>
                <Td>{tool.StartingPrice}</Td>
                <Td>
                  {reviews[tool.Name] ? (
                    <Button
                      colorScheme="teal"
                      size="sm"
                      onClick={() => navigate(`/tool-review/${tool.Name}`)}
                    >
                      See Review
                    </Button>
                  ) : (
                    <Text fontSize="sm" color="gray.500">
                      Review Pending
                    </Text>
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};

export default ToolsSection;
