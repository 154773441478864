import React from "react";
import { Flex, Box, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Logo from "./Logo";

const Header = ({ onSidebarOpen }) => {
  return (
    <Flex as="header" align="center" justify="space-between" p={2}>
      <IconButton
        icon={<HamburgerIcon />}
        variant="ghost"
        color="black"
        onClick={onSidebarOpen}
        fontSize="2xl"
        ml={3}
      />
      <Box flex="3" textAlign="left" ml={3} mb={2}>
        <Logo />
      </Box>
    </Flex>
  );
};

export default Header;
