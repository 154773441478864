import React, { useState, useEffect } from "react";
import {
  Container,
  Heading,
  Grid,
  GridItem,
  Text,
  Button,
  Link,
  Flex,
  Box,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { getBlueprintDetail } from "../Services/api";
import BreadcrumbsWithButton from "../Components/Breadcrumbs";
import { ArrowForwardIcon } from "@chakra-ui/icons";

// Function to ensure links open in a new tab
const processHtmlContent = (html) => {
  // Create a temporary DOM element to manipulate the content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Find all anchor elements and add target="_blank" to open in a new tab
  const links = tempDiv.querySelectorAll("a");
  links.forEach((link) => {
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer"); // Security improvement
  });

  return tempDiv.innerHTML;
};

const formatArticleId = (id) => id.replace(/([a-z])([A-Z])/g, "$1 $2");

const Blueprint = () => {
  const { articleId, categoryId } = useParams();
  const [blueprint, setBlueprint] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlueprintData = async () => {
      try {
        const blueprintData = await getBlueprintDetail(articleId);

        if (blueprintData) {
          setBlueprint(blueprintData);
        } else {
          // No data found, set blueprint to null
          setBlueprint(null);
        }
      } catch (err) {
        // Error fetching data, set blueprint to null
        setBlueprint(null);
      } finally {
        setLoading(false);
      }
    };
    fetchBlueprintData();
  }, [articleId]);

  if (loading) return <Text>Loading...</Text>;

  if (blueprint) {
    const totalExpenses = blueprint?.financials?.expenses?.reduce(
      (total, expense) => {
        return total + (parseFloat(expense.amount.replace("£", "")) || 0);
      },
      0
    );

    return (
      <Container maxW="container.xl" py={5}>
        <BreadcrumbsWithButton
          category={categoryId || "Unknown Category"}
          categoryId={categoryId}
          articleTitle={articleId}
          blueprintTitle="Blueprint"
          hideButton={true}
        />

        {/* Title and Video Button */}
        <Flex justify="space-between" align="center" mb={6}>
          <Heading as="h1" size="2xl">
            The {formatArticleId(articleId)} Blueprint
          </Heading>
          {blueprint?.videoUrl && (
            <Link href={blueprint.videoUrl} isExternal>
              <Button
                color="white"
                bg="#e63946"
                size="md"
                rightIcon={<ArrowForwardIcon />}
              >
                Blueprint Video
              </Button>
            </Link>
          )}
        </Flex>

        <Grid templateColumns="1fr 4fr" gap={6}>
          {/* Floating Sidebar */}
          <Box
            as="aside"
            position="sticky"
            top="100px"
            height="fit-content"
            bg="#f5f5f5"
            p={4}
            borderRadius="md"
            boxShadow="md"
          >
            {blueprint.sections.map((section) => (
              <Link
                key={section.id}
                href={`#${section.id}`}
                display="block"
                mb={3}
                color="#e63946"
                fontSize="lg"
                fontWeight="bold"
                textDecoration="none"
                _hover={{ color: "#1d3557", textDecoration: "underline" }}
              >
                {section.title}
              </Link>
            ))}

            {/* Add the financials section manually */}
            <Link
              href="#financials"
              display="block"
              mb={3}
              color="#e63946"
              fontSize="lg"
              fontWeight="bold"
              textDecoration="none"
              _hover={{ color: "#1d3557", textDecoration: "underline" }}
            >
              Financials
            </Link>
          </Box>

          {/* Main Content */}
          <GridItem colSpan={1}>
            {blueprint.sections.map((section) => (
              <Box
                id={section.id}
                key={section.id}
                bg="white"
                p={5}
                boxShadow="md"
                borderRadius="md"
                mb={6}
              >
                <Heading as="h2" size="lg" mb={4}>
                  {section.title}
                </Heading>

                <Grid templateColumns="2fr 1fr" gap={6}>
                  {/* Left Column: Main HTML Content */}
                  <Box>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: processHtmlContent(section.content),
                      }}
                    />
                  </Box>

                  {/* Right Column: Fees Box for prerequisites section */}
                  {section.id === "prerequisites" && blueprint.fees && (
                    <Box bg="gray.100" p={5} borderRadius="md">
                      <Heading as="h3" size="md" mb={4}>
                        Fees to Keep in Mind
                      </Heading>
                      {blueprint.fees.map((fee, index) => (
                        <Text key={index} fontSize="sm" mb={2}>
                          {fee}
                        </Text>
                      ))}
                    </Box>
                  )}
                </Grid>
              </Box>
            ))}

            {/* Financials Section */}
            <Box
              id="financials"
              bg="white"
              p={5}
              boxShadow="md"
              borderRadius="md"
              mb={6}
            >
              <Heading as="h2" size="lg" mb={4}>
                Financials
              </Heading>
              <Box as="table" w="full" textAlign="left" borderRadius="md">
                <Box as="thead">
                  <Box as="tr">
                    <Box as="th" p={2}>
                      Type
                    </Box>
                    <Box as="th" p={2}>
                      Expense Name
                    </Box>
                    <Box as="th" p={2}>
                      Amount (£)
                    </Box>
                  </Box>
                </Box>
                <Box as="tbody">
                  {blueprint.financials.expenses.map((expense, index) => (
                    <Box as="tr" key={index}>
                      <Box as="td" p={2}>
                        {expense.type}
                      </Box>
                      <Box as="td" p={2}>
                        {expense.name}
                      </Box>
                      <Box as="td" p={2}>
                        {expense.amount}
                      </Box>
                    </Box>
                  ))}
                  {/* Display the Total */}
                  <Box as="tr">
                    <Box as="td" colSpan={2} p={2} fontWeight="bold">
                      Total Expenses:
                    </Box>
                    <Box as="td" p={2} fontWeight="bold">
                      £{totalExpenses?.toFixed(2)}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    );
  } else {
    // Display "Coming Soon" if there's no blueprint data
    return (
      <Container maxW="container.xl" py={5}>
        <BreadcrumbsWithButton
          category={categoryId || "Unknown Category"}
          categoryId={categoryId}
          articleTitle={articleId}
          blueprintTitle="Blueprint"
          hideButton={true}
        />
        <Box
          minH="400px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="#a8dadc" // Blue background
          borderRadius="lg"
          textAlign="center"
        >
          <Heading as="h1" size="2xl" color="black">
            Coming Soon
          </Heading>
        </Box>
      </Container>
    );
  }
};

export default Blueprint;
