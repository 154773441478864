import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import { ChevronRightIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useLocation } from "react-router-dom";

// Helper function to encode the articleTitle for URL
const formatForUrl = (title) => encodeURIComponent(title.trim());

const BreadcrumbsWithButton = ({
  category,
  categoryId,
  articleTitle,
  blueprintTitle,
  onShowBlueprint,
  hideButton = false,
}) => {
  const location = useLocation();
  const formattedArticleTitle = formatForUrl(articleTitle);

  return (
    <Box mb={6} position="relative" overflow="hidden">
      <Flex
        direction={["column", "row"]} // Stack vertically on small screens, horizontal on larger
        alignItems={["flex-start", "center"]} // Align items differently based on screen size
        wrap="wrap"
        width="65%" // Full width of parent container
        maxWidth="65%" // Prevent overflow
      >
        <Box
          px={4}
          py={2}
          bg="blue.50"
          borderRadius="md"
          flex="1" // Allow box to grow and shrink within flex
          whiteSpace="normal" // Allow line breaks for long text
          overflowWrap="break-word" // Ensure long words break nicely
        >
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon color="gray.500" />}
            fontWeight="medium"
            fontSize={["xs", "sm"]} // Smaller font size for breadcrumbs on smaller screens
          >
            {/* Home Link */}
            <BreadcrumbItem>
              <BreadcrumbLink
                as={RouterLink}
                to="/"
                color="blue.600"
                _hover={{ textDecor: "underline" }}
              >
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            {/* Categories Link */}
            <BreadcrumbItem>
              <BreadcrumbLink
                as={RouterLink}
                to="/categories"
                color="blue.600"
                _hover={{ textDecor: "underline" }}
              >
                Categories
              </BreadcrumbLink>
            </BreadcrumbItem>

            {/* Category Link */}
            <BreadcrumbItem>
              <BreadcrumbLink
                as={RouterLink}
                to={`/categories/${categoryId}`}
                color="blue.600"
                _hover={{ textDecor: "underline" }}
              >
                {category}
              </BreadcrumbLink>
            </BreadcrumbItem>

            {/* Article Title (Plain Text if it's the current page) */}
            {articleTitle && (
              <BreadcrumbItem
                isCurrentPage={location.pathname.includes(
                  `/articles/${formattedArticleTitle}`
                )}
              >
                {location.pathname ===
                `/categories/${categoryId}/articles/${formattedArticleTitle}` ? (
                  <Text color="gray.800">{articleTitle}</Text>
                ) : (
                  <BreadcrumbLink
                    as={RouterLink}
                    to={`/categories/${categoryId}/articles/${formattedArticleTitle}`}
                    color="blue.600"
                    _hover={{ textDecor: "underline" }}
                  >
                    {articleTitle}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            )}

            {/* Blueprint Title (Plain Text as it's always the current page) */}
            {blueprintTitle && (
              <BreadcrumbItem isCurrentPage={true}>
                <Text color="gray.800">{blueprintTitle}</Text>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Box>

        {!hideButton && (
          <Button
            size="sm"
            onClick={onShowBlueprint}
            mt={[2, 0]} // Margin top on small screens, none on larger
            ml={[0, 4]} // No left margin on small, margin on larger
            bg="#e63946"
            color="white"
            _hover={{ bg: "gray" }}
            rightIcon={<ArrowForwardIcon />}
            alignSelf={["flex-start", "center"]} // Align button to the left on small, center on larger
          >
            Step by Step Guide
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default BreadcrumbsWithButton;
