import React from "react";
import { Text, Box, Button, Flex, Heading, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const About = () => {
  return (
    <Flex direction="column" align="center" textAlign="left" py={10}>
      {/* First Section */}
      <Flex
        direction={["column", "row"]} // Stack vertically for small screens
        justify="space-between"
        w="full"
        maxW="1200px"
        py={10}
      >
        {/* Left side: About Us, Headline and Button */}
        <Box maxW={["100%", "50%"]} px={5} mb={[8, 0]}>
          <Text
            as="h1"
            fontSize={["2xl", "3xl", "4xl"]}
            fontWeight="bold"
            color="#e63946"
            mb={4}
          >
            About this site
          </Text>
          <Heading
            as="h2"
            size="2xl"
            fontWeight="bold"
            mb={4}
            textAlign={["center", "left"]} // Center align for small screens
          >
            Supporting future business owners with step-by-step guides to
            starting a business!
          </Heading>
          <Flex justifyContent={["center", "flex-start"]}>
            <Button
              as={RouterLink}
              to="/categories"
              colorScheme="red"
              size="lg"
              mt={4}
            >
              Explore Businesses
            </Button>
          </Flex>
        </Box>

        {/* Right side: Explanatory text */}
        <Box maxW={["100%", "45%"]} px={5}>
          {/* Section 1 - How this site got started */}
          <Heading as="h3" size="lg" mb={4} color="#e63946" textAlign={["center", "left"]}>
            How it started:
          </Heading>

          <Text fontSize="lg" mb={6}>
            I created this site to cut through the conflicting information on
            starting an online business. My goal is to offer easy-to-navigate,
            practical resources to help people get started quickly and with
            clear guidance, all for free.
          </Text>

          <Heading as="h3" size="lg" mb={4} color="#e63946" textAlign={["center", "left"]}>
            How it will grow:
          </Heading>

          <Text fontSize="lg" mb={6}>
            I want this site to explore different business ideas and test their
            profit potential, sharing the journey with you. Since this takes
            time, some information may be missing, and in such cases, you’ll
            find a 'Coming Soon' message.
          </Text>
        </Box>
      </Flex>

      {/* Second section with features */}
      <Box w="full" bg="#e63946" py={10}>
        <Flex
          direction="column"
          align="flex-start"
          maxW="1200px"
          mx="auto"
          color="white"
          px={5}
        >
          <Text fontSize="3xl" fontWeight="bold" mb={6} color="white" textAlign={["center", "left"]}>
            What We Offer
          </Text>
          <Box
            as="ul"
            fontSize="lg"
            textAlign="left"
            style={{ listStyleType: "disc", paddingLeft: "1.5rem" }}
          >
            <Box as="li" mb={4}>
              Step-by-step guide on how to start specific businesses (Print on
              Demand, Consulting, etc.)
            </Box>
            <Box as="li" mb={4}>
              Free{" "}
              <Link
                as={RouterLink}
                to="/resources"
                textDecoration="underline"
                color="darkblue"
                _hover={{ color: "gray.200" }}
              >
                resources
              </Link>{" "}
              such as Financial Budget Templates, Project Planning for your
              business
            </Box>
            <Box as="li">
              Best{" "}
              <Link
                as={RouterLink}
                to="/tools"
                textDecoration="underline"
                color="darkblue"
                _hover={{ color: "gray.200" }}
              >
                tools
              </Link>{" "}
              in the industry for your specific niche
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* Additional Disclaimer */}
      <Box w="full" textAlign="left" mt={5} px={5}>
        <Text fontSize="md" mb={6} color="black" fontStyle="italic">
          Please note there may be some affiliate links on this site, but this
          comes at no cost to you. Only useful products are recommended and we
          prioritize free tools.
        </Text>
      </Box>
    </Flex>
  );
};

export default About;
