import React from "react";
import { Box, Text, Link, Divider, Stack } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box as="footer" bg="gray.800" color="white" py={2} px={8}>
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
      >
        {/* Business Info */}
        <Box>
          <Text mt={2} color="white">
            &copy; {new Date().getFullYear()} Your Virtual Solutions Ltd. All
            rights reserved
          </Text>
        </Box>
        <Link
          href="/terms-and-conditions"
          color="teal.400"
          _hover={{ textDecoration: "underline" }}
        >
          Terms and Conditions
        </Link>
        <Link
          href="/privacy-policy"
          color="teal.400"
          _hover={{ textDecoration: "underline" }}
        >
          Privacy Policy
        </Link>
      </Stack>

      <Divider my={1} borderColor="gray.600" />
    </Box>
  );
};

export default Footer;
