import React, { useEffect, useState } from "react";
import { Box, Heading, Link, Grid, Text, Image } from "@chakra-ui/react";
import { getToolsByTag } from "../Services/api";

const RelatedReviews = ({ tags, currentToolName }) => {
  const [relatedTools, setRelatedTools] = useState([]);

  useEffect(() => {
    const fetchRelatedTools = async () => {
      if (tags && tags.length > 0) {
       
        const related = await getToolsByTag(tags[0]);

      

        const filteredTools = related.filter((tool) => {
          
          return tool.toolName !== currentToolName;
        });

       
        setRelatedTools(filteredTools);
      }
    };
    fetchRelatedTools();
  }, [tags, currentToolName]);

  return (
    <Box
      bg="#ececec"
      p={8}
      borderRadius="lg"
      boxShadow="xl"
      mb={6}
      mt={10}
      borderColor="black"
      borderWidth={2}
    >
      <Heading as="h2" size="lg" color="#e63946" mb={5}>
        Related Reviews
      </Heading>
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        {relatedTools.map((relatedTool, index) => (
          <Link key={index} href={`/tool-review/${relatedTool.toolName}`}>
            <Box
              bg="white"
              p={6}
              borderRadius="lg"
              boxShadow="base" // Softer shadow
              textAlign="center"
              transition="transform 0.2s, box-shadow 0.2s"
              _hover={{
                transform: "scale(1.02)", // Gentle scale on hover
                boxShadow: "md", // Slightly larger shadow on hover
              }}
              border="1px solid #eee" // Subtle border
            >
              <Image
                src={relatedTool.logoUrl}
                alt={relatedTool.toolName}
                boxSize="50px"
                mx="auto"
                mb={3}
                loading="lazy"
              />
              <Text fontWeight="bold" color="black" fontSize="lg">
                {relatedTool.toolName}
              </Text>
            </Box>
          </Link>
        ))}
      </Grid>
    </Box>
  );
};

export default RelatedReviews;
