import React, { useState, useEffect } from "react";
import { Box, Heading, Text, SimpleGrid, Image, Skeleton } from "@chakra-ui/react";
import { getBlogPosts } from "../Services/contentful";
import { Link as RouterLink } from "react-router-dom";

const BlogListing = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        console.log('Fetching blogs');
        const blogPosts = await getBlogPosts();
        console.log('Fetched blog posts:', blogPosts);
        setBlogs(blogPosts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <Heading as="h1" size="2xl" mb={8} textAlign="center">
        Blog
      </Heading>
      {loading ? (
        <SimpleGrid columns={[1, 1, 2]} spacing={8}>
          <Skeleton height="400px" />
          <Skeleton height="400px" />
          <Skeleton height="400px" />
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={[1, 2, 3]} spacing={8} maxWidth={"1200px"} mx="auto">
          {blogs.map((blog) => (
            <Box
              key={blog.sys.id}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p={5}
              bg="white"
              boxShadow="md"
            >
              {blog.fields.featuredImage && (
                <Image
                  src={blog.fields.featuredImage.fields.file.url}
                  alt={blog.fields.title}
                  mb={4}
                  borderRadius="md"
                  objectFit="cover"
                  height={["150px", "200px", "220px"]}
                  width="100%"
                />
              )}
              <Heading as="h2" size="lg" mb={4} textAlign="center">
                <Box
                  as={RouterLink}
                  to={`/blog/${blog.fields.blogSlug}`}
                  textDecoration="none"
                  _hover={{ cursor: "pointer" }}
                >
                  {blog.fields.title}
                </Box>
              </Heading>
              <Text mb={4}>{blog.fields.excerpt || "No excerpt available."}</Text>
              <Text fontSize="sm" color="gray.500" textAlign="right" mb={4}>
                {blog.fields.publishDate ? new Date(blog.fields.publishDate).toLocaleDateString() : "Publish date not available"}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </>
  );
};

export default BlogListing;
