import React from "react";
import { Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <Text
        fontSize={{ base: "2xl", md: "4xl" }}
        fontWeight="bolder"
        color="#e63946"
        fontFamily="heading"
        whiteSpace="nowrap"
      >
        The Business Blueprint
      </Text>
    </Link>
  );
};

export default Logo;
