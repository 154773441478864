import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  Flex,
  Tag,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { StarIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { getToolReviewDetail } from "../Services/api";
import VideoPlayer from "../Components/VideoPlayer";
import RelatedReviews from "../Components/RelatedReviews";
import LazyLoad from "react-lazyload";

const ToolReview = () => {
  const { toolId } = useParams();
  const [toolData, setToolData] = useState(null);
  const [loading, setLoading] = useState(true); // loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToolData = async () => {
      try {
        const data = await getToolReviewDetail(toolId);
        if (!data.review) {
          navigate("/coming-soon"); 
        } else {
          setToolData(data);
        }
      } catch (error) {
        console.error("Error fetching tool data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchToolData();
  }, [toolId, navigate]);

  if (loading) {
    return (
      <Container maxW="container.xl" py={10}>
        <Skeleton height="40px" mb={4} />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
        <Skeleton height="300px" mb={4} />
        <Skeleton height="50px" width="100px" />
      </Container>
    );
  }

  if (!toolData) return <Text>Tool not found</Text>;

  const overallRating = toolData?.review
    ? (
        (parseFloat(toolData.review.easeOfUse || 0) +
          parseFloat(toolData.review.pricingToValue || 0) +
          parseFloat(toolData.review.integrationWorkflow || 0) +
          parseFloat(toolData.review.securityCompliance || 0) +
          parseFloat(toolData.review.scalability || 0)) / 5
      ).toFixed(1)
    : "N/A";

  const renderStars = (rating, size = "md") => {
    const stars = [];
    const roundedRating = Math.round(rating * 2) / 2;
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(roundedRating)) {
        stars.push(
          <StarIcon
            key={i}
            color="#e63946"
            boxSize={size === "lg" ? "25px" : "15px"}
          />
        );
      } else if (i === Math.floor(roundedRating) && roundedRating % 1 !== 0) {
        stars.push(
          <StarIcon
            key={i}
            color="#e63946"
            boxSize={size === "lg" ? "25px" : "15px"}
            style={{ clipPath: "inset(0 50% 0 0)" }}
          />
        );
      } else {
        stars.push(
          <StarIcon
            key={i}
            color="lightGrey"
            boxSize={size === "lg" ? "25px" : "15px"}
          />
        );
      }
    }
    return stars;
  };

  return (
    <Container maxW="container.xl" py={10}>
      <Flex justify="space-between" mb={8}>
        <Box maxWidth="60%">
          <Heading as="h1" fontSize="4xl" mb={3}>
            {toolData.toolName}
          </Heading>
          <Text fontSize="lg" color="black">
            {toolData.toolDescription}
          </Text>
        </Box>

        <Box
          textAlign="right"
          backgroundColor="white"
          borderRadius="lg"
          p={4}
          boxShadow="xl"
          height="150px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Heading as="h2" size="lg" color="#e63946">
            Overall Rating: {overallRating}/5
          </Heading>
          <Flex justify="flex-end" mt={2}>
            {overallRating !== "N/A" ? renderStars(overallRating, "lg") : null}
          </Flex>
        </Box>
      </Flex>

      <Flex justify="flex-end" mb={8}>
        <a
          href={toolData.affiliateLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            as="button"
            backgroundColor="#e63946"
            color="white"
            px={6}
            py={3}
            borderRadius="md"
            boxShadow="md"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            _hover={{ backgroundColor: "teal" }}
          >
            Go To Site
            <ExternalLinkIcon ml={2} />
          </Box>
        </a>
      </Flex>

      <Box bg="white" p={8} borderRadius="lg" boxShadow="md" mb={10}>
        <Heading as="h2" size="lg" color="black" mb={5}>
          Pricing Plans
        </Heading>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          {toolData.pricingPlans?.map((plan, index) => (
            <Box
              key={index}
              bg="gray.50"
              p={5}
              borderRadius="md"
              boxShadow="sm"
            >
              <Heading as="h3" size="md" color="#e63946" mb={2}>
                {plan.name}
              </Heading>
              <Tag size="lg" colorScheme="blue" mb={2}>
                {plan.price}
              </Tag>
              <Flex direction="column" align="start">
                <Text fontSize="sm" fontWeight="bold" textAlign="left">
                  Best for:
                </Text>
                <Box as="ul" pl={4} listStyleType="disc">
                  {plan.benefits.split(", ").map((benefit, idx) => (
                    <Text as="li" key={idx} color="gray.600" fontSize="sm">
                      {benefit}
                    </Text>
                  ))}
                </Box>
              </Flex>
            </Box>
          ))}
        </Grid>
      </Box>

      <Box bg="white" p={8} borderRadius="lg" boxShadow="md" mb={10}>
        <Heading as="h2" size="lg" color="black" mb={5}>
          Our Rating Explained
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <Box>
            <Heading as="h3" size="md" color="black">
              Ease of Use
            </Heading>
            <Flex>{toolData.review && renderStars(toolData.review.easeOfUse)}</Flex>
            <Text>{toolData.review?.explaination?.easeOfUse}</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" color="black">
              Pricing to Value
            </Heading>
            <Flex>{toolData.review && renderStars(toolData.review.pricingToValue)}</Flex>
            <Text>{toolData.review?.explaination?.pricingToValue}</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" color="black">
              Integration Workflow
            </Heading>
            <Flex>
              {toolData.review && renderStars(toolData.review.integrationWorkflow)}
            </Flex>
            <Text>{toolData.review?.explaination?.integrationWorkflow}</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" color="black">
              Security & Compliance
            </Heading>
            <Flex>{toolData.review && renderStars(toolData.review.securityCompliance)}</Flex>
            <Text>{toolData.review?.explaination?.securityCompliance}</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" color="black">
              Scalability
            </Heading>
            <Flex>{toolData.review && renderStars(toolData.review.scalability)}</Flex>
            <Text>{toolData.review?.explaination?.scalability}</Text>
          </Box>
        </Grid>
      </Box>

      <Box bg="white" p={8} borderRadius="lg" boxShadow="md">
        <Heading as="h2" size="lg" color="black" mb={5}>
          How-To Video
        </Heading>
        <LazyLoad height={300} offset={100}>
          <VideoPlayer src={toolData.howToVideo} />
        </LazyLoad>
      </Box>

      <RelatedReviews tags={toolData.tags} currentToolName={toolData.toolName} />
    </Container>
  );
};

export default ToolReview;
