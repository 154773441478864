import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Tag,
  Image,
  Text,
  Divider,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  FaSearch,
  FaToolbox,
  FaChartLine,
  FaCartArrowDown,
  FaCheckCircle,
  FaVideo,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getTools } from "../Services/api";

const categories = [
  { name: "All Tools", icon: FaCheckCircle },
  { name: "Marketing", icon: FaToolbox },
  { name: "CRM", icon: FaChartLine },
  { name: "Ecommerce", icon: FaCartArrowDown },
  { name: "Content Creation", icon: FaVideo },
];

const ToolsDirectory = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Tools");
  const [searchQuery, setSearchQuery] = useState("");
  const [toolsData, setToolsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTools = async () => {
      const tools = await getTools();
      setToolsData(tools);
    };

    fetchTools();
  }, []);

  const filteredTools = toolsData.filter((tool) => {
    const matchesCategory =
      selectedCategory === "All Tools" || tool.toolCategory === selectedCategory;
    const matchesSearch = tool.toolName
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const getPricingTooltip = (pricingTag) => {
    switch (pricingTag) {
      case "Freemium":
        return "Basic features are free, with optional paid upgrades.";
      case "Free (Profit Split)":
        return "Free to use, but platform takes a percentage of your profits.";
      default:
        return null;
    }
  };

  const handleReviewClick = (tool) => {
    if (tool.review) {
      navigate(`/tool-review/${tool.toolName}`);
    } else {
      navigate(`/coming-soon`);
    }
  };

  return (
    <Box p={5}>
      {/* Header Section */}
      <Flex justify="space-between" alignItems="center" mb={[8, 5]} direction={["column", "row"]}>
        <Heading as="h1" size="xl" mb={[4, 0]}>
          All Tools Directory
        </Heading>

        {/* Input Group with added margin for smaller screens */}
        <InputGroup width={["100%", "300px"]} mb={[4, 0]}>
          <InputLeftElement pointerEvents="none">
            <Icon as={FaSearch} color="gray.500" />
          </InputLeftElement>
          <Input
            placeholder="Search for a tool..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            bg="white"
          />
        </InputGroup>
      </Flex>

      {/* Categories Buttons */}
      <Flex mb={5} justifyContent="center" wrap="wrap">
        {categories.map((category) => (
          <Button
            key={category.name}
            leftIcon={<Icon as={category.icon} />}
            mx={1}
            mb={2}
            colorScheme={selectedCategory === category.name ? "red" : "gray"}
            onClick={() => setSelectedCategory(category.name)}
            w={["100%", "180px"]}
          >
            {category.name}
          </Button>
        ))}
      </Flex>

      {selectedCategory !== "All Tools" && (
        <>
          <Heading as="h2" size="lg" mb={2}>
            {selectedCategory}
          </Heading>
          <Divider mb={5} borderColor="white" borderWidth="2px" />
        </>
      )}

      {/* Grid Section with Responsive Column Adjustment */}
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)", // 1 column for small screens
          sm: "repeat(2, 1fr)", // 2 columns for tablets and small devices like iPad Mini
          md: "repeat(3, 1fr)", // 3 columns for iPad Pro
          lg: "repeat(4, 1fr)", // 4 columns for desktops
        }}
        gap={6}
      >
        {filteredTools.map((tool) => (
          <Box
            key={tool.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={5}
            bg="white"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="400px"
          >
            {/* Content section */}
            <Box flex="1">
              <Image
                src={tool.logoUrl}
                alt={tool.toolName}
                boxSize="80px"
                mx="auto"
                mb={3}
                loading="lazy"
              />
              <Heading as="h3" size="md" color="black">
                {tool.toolName}
              </Heading>
              <Tooltip label={getPricingTooltip(tool.pricingTag)} hasArrow>
                <Tag mt={3} colorScheme="teal">
                  {tool.pricingTag}
                </Tag>
              </Tooltip>
              <Text fontSize="sm" mt={2} color="black">
                {tool.toolDescription}
              </Text>
            </Box>

            {/* Button Section */}
            <Flex
              mt={4}
              direction={{ base: "column", lg: "row" }} // Stacks for smaller screens, side-by-side for larger
              alignItems={{ base: "stretch", lg: "center" }} // Stretch buttons to full width on smaller screens
              justifyContent={{ lg: "space-between" }}
              gap={2} // Add space between buttons to avoid overlap
              width="100%"
            >
              <Button
                colorScheme="red"
                w={{ base: "100%", lg: "48%" }} // Full width for smaller screens, 48% for side-by-side on larger
                onClick={() => handleReviewClick(tool)}
                fontSize="sm"
                px={4}
              >
                Read Review
              </Button>

              <Button
                colorScheme="teal"
                variant="outline"
                w={{ base: "100%", lg: "48%" }} // Full width for smaller screens, 48% for side-by-side on larger
                as="a"
                href={tool.website}
                target="_blank"
                rightIcon={<ExternalLinkIcon />}
                fontSize="sm"
                px={4}
              >
                Go To Site
              </Button>
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default ToolsDirectory;
