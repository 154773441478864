import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const ComingSoon = () => {
  return (
    <Box textAlign="center" py={40} px={10} height="70vh">
      <Heading as="h1" fontSize="6xl" color="#e63946" mb={8}>
        Coming Soon
      </Heading>
      <Text fontSize="2xl" color="gray.600">
        This content is currently being prepared. Please check back
        soon!
      </Text>
    </Box>
  );
};

export default ComingSoon;
