import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,  // Your space ID
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,  // Your API access token
  environment: 'master'
});

export const getBlogPosts = async () => {
    try {
      console.log('Fetching blog posts from Contentful with correct content type');
      const response = await client.getEntries({ content_type: 'blog' }); // Ensure this uses 'blog'
      console.log('Contentful response:', response);
      return response.items;
    } catch (error) {
      console.error('Error fetching blog posts:', error); // Log the error details
      throw error;
    }
  };
  

export const getBlogPostBySlug = async (slug) => {
  try {
    const response = await client.getEntries({
      content_type: 'blog',  // Use lowercase 'blog' here too
      'fields.blogSlug': slug,
    });
    console.log('Fetched blog post by slug:', response.items[0]); // Log fetched blog post
    return response.items[0];
  } catch (error) {
    console.error('Error fetching blog post by slug:', error); // Log error details
    throw error;
  }
};
