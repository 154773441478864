import React from "react";
import { Box, Image } from "@chakra-ui/react";

const ToolIcon = ({ logo, altText }) => (
  <Box
    boxSize="50px" // Specifies the size of the icon container.
    borderRadius="full" // Makes the container circular.
    overflow="hidden" // Ensures no part of the image bleeds outside the container.
    bg="gray.200" // Sets a background color for better visibility of transparent images.
    display="flex"
    justifyContent="center"
    alignItems="center"
    mr={2} // Adds margin to the right.
  >
    <Image
      src={logo}
      alt={altText}
      boxSize="100%" // Fills the container completely.
      objectFit="contain" // Changes here to show the full logo aspect correctly.
      loading="lazy" // Lazy loads the image for better performance.
    />
  </Box>
);

export default ToolIcon;
