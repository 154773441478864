import React from "react";
import {
  Box,
  Text,
  Button,
  Heading,
  Flex,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { FaToolbox, FaBook, FaRocket, FaArrowRight } from "react-icons/fa"; // Added FaArrowRight for button arrow
import { Link as RouterLink } from "react-router-dom";

const Home = () => {
  return (
    <Box mb={2}>
      {/* Main Banner Section */}
      <Box textAlign="center" py={19} color="black">
        <Heading as="h1" size="2xl" mb={10}>
          Start Your Dream Business Today
        </Heading>
        <Text fontSize="xl" mb={8} textAlign="justify" px={[4, 8]}>
          Get all the free tools, guides, and resources you need to turn your
          business idea into reality. Whether you're exploring Print-on-Demand,
          Consultancy, or wondering about a Burger Truck , we help you test your
          idea's profitability and get started.
        </Text>
        {/* <Text fontSize="xl" mb={8} textAlign="justify" px={[4, 8]}>
          <span style={{ color: "#e63946", fontWeight: "bold" }}>
            This site is free to use
          </span>
          , and we’ll never charge for courses or information. We may include
          affiliate links to recommended products, which help support us at no
          extra cost to you.
        </Text> */}

        <Button
          color="#e63946"
          size="lg"
          as={RouterLink}
          to="/categories"
          rightIcon={<FaArrowRight />} // Added arrow icon to button
        >
          Browse Business Models
        </Button>
      </Box>

      {/* What We Offer Section */}
      <Box textAlign="center" py={7}>
        <Heading as="h2" size="xl" mb={10}>
          What We Offer
        </Heading>

        <Flex justify="center" gap={10} flexWrap="wrap">
          {/* Business Guides */}
          <VStack maxW="300px">
            <Icon as={FaToolbox} boxSize={10} color="#e63946" />{" "}
            {/* Updated color */}
            <Text fontSize="lg" fontWeight="bold">
              Business Guides
            </Text>
            <Text textAlign="justify">
              Step-by-step blueprints for different business models.
            </Text>
          </VStack>

          {/* Financial Tools */}
          <VStack maxW="300px">
            <Icon as={FaBook} boxSize={10} color="#1d3557" />{" "}
            {/* Updated color */}
            <Text fontSize="lg" fontWeight="bold">
              Financial Tools
            </Text>
            <Text textAlign="justify">
              Free tools to plan and track your business finances.
            </Text>
          </VStack>

          {/* Marketing Resources */}
          <VStack maxW="300px">
            <Icon as={FaRocket} boxSize={10} color="#457b9d" />{" "}
            {/* Updated color */}
            <Text fontSize="lg" fontWeight="bold">
              Marketing Resources
            </Text>
            <Text textAlign="justify">
              Tips and templates to promote your business.
            </Text>
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default Home;
