import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";

const LayoutInner = ({ children }) => {
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";

  return (
    <Box
      bg={isAboutPage ? "white" : "#a8dadc"}
      p={[1, 3, 4]} // Adjust padding for different screen sizes
      borderRadius="lg"
      maxWidth={["100%", "100%", "90%"]}
      mx="auto"
    >
      {children}
    </Box>
  );
};

export default LayoutInner;
