import React from "react";
import { Box, Heading } from "@chakra-ui/react";

const PrivacyPolicy = () => {
  const privacyPolicyHTML = `
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 18px;"><strong>Last Updated:</strong> September 28th, 2024</p>
    </br>
   
    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">1. Introduction</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">At <strong>The Business Blueprint</strong>, we respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and disclose information through our website.</p>
    
    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">2. Information We Collect</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">We do not directly collect any personal data from users. However, we use third-party services that may collect certain data automatically through cookies, including:</p>
    <ul style="margin-left: 20px; list-style-type: disc; color: #595959; font-family: Arial, Calibri; font-size: 15px;">
      <li>IP Address</li>
      <li>Location Data (inferred from IP)</li>
      <li>Hardware and Software Details (e.g., browser type)</li>
      <li>Clicked Links</li>
      <li>Content Viewed</li>
    </ul>

    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">3. Cookies</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">We use cookies to improve user experience and for analytics purposes. These cookies may be set by third-party services such as Google Analytics and affiliate platforms. By continuing to use our website, you consent to the use of cookies.</p>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">You have the option to manage or opt-out of non-essential cookies through your browser settings.</p>
    
    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">4. Affiliate Disclosure</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">Some links on this website are <strong>affiliate links</strong>, which means we may earn a commission if you click or make a purchase through these links at no additional cost to you. We only recommend products and services we believe provide value to our users.</p>
    
    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">5. How We Use the Data</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">The information collected automatically (e.g., via cookies) is used for the following purposes:</p>
    <ul style="margin-left: 20px; list-style-type: disc; color: #595959; font-family: Arial, Calibri; font-size: 15px;">
      <li>To analyze website traffic and user interactions (Google Analytics).</li>
      <li>To track affiliate links and earn commissions from purchases.</li>
    </ul>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">We do not sell or share any personal data collected via cookies with third parties other than the services mentioned above.</p>

    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">6. Data Retention</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">We retain cookies and tracking data for as long as necessary to fulfill the purposes outlined in this policy or as required by the third-party services we use (such as Google Analytics or affiliate programs).</p>

    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">7. Security</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">We take reasonable steps to ensure the security of any data collected through third-party services. Our website is hosted on <strong>Netlify</strong>, which uses industry-standard security protocols such as <strong>HTTPS encryption</strong>.</p>

    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">8. Data Transfers</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">Some of the third-party services we use, such as Google Analytics and affiliate platforms, may transfer data outside of the UK or EU, including to the <strong>United States</strong>. These services comply with international data protection standards.</p>
    
    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">9. Users' Rights</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">As a user, you have the right to:</p>
    <ul style="margin-left: 20px; list-style-type: disc; color: #595959; font-family: Arial, Calibri; font-size: 15px;">
      <li>Opt-out of cookie tracking by adjusting your browser settings.</li>
      <li>Request more information about how we use your data.</li>
    </ul>
    </br>
    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">10. Changes to This Privacy Policy</h2>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">We may update this Privacy Policy from time to time. Changes will be indicated by the <strong>"Last Updated"</strong> date at the top of the policy. We encourage you to periodically review this page to stay informed about our privacy practices.</p>
</br>
    <h2 style="font-size: 19px; margin-top: 20px; font-weight: bold; color: black; font-family: Arial, Calibri;">11. Contact Us</h2>
    
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;">If you have any questions or concerns about this Privacy Policy, you can contact us at:</p>
    </br>
    
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;"><strong>Website:</strong> www.the-businessblueprint.com</p>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;"><strong>Address:</strong> 128 City Road, London, EC1V 2NX</p>
    <p style="color: #595959; font-family: Arial, Calibri; font-size: 15px;"><strong>Email:</strong> contactbusinessblueprint@gmail.com</p>
  `;

  return (
    <Box p={10}>
      <Heading as="h1" size="xl" mb={6}>
        Privacy Policy
      </Heading>
      <Box dangerouslySetInnerHTML={{ __html: privacyPolicyHTML }} />
    </Box>
  );
};

export default PrivacyPolicy;
