import React from "react";
import {
  Box,
  Heading,
  List,
  ListItem,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";

const SidebarContent = ({ article }) => {
  return (
    <>
      <Box mb={6}>
        <Heading as="h3" size="lg" mb={4}>
          Business Inspiration
        </Heading>
        <List spacing={3}>
          {Object.values(article.businessExamples || {}).map((example, index) => (
            <ListItem key={index}>
              <Link href={example.url} isExternal textDecoration="underline">
                {example.Name}
              </Link>
              <Text fontSize="sm">{example.Description}</Text>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Further Reading Section */}
      <Accordion allowToggle defaultIndex={null}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h3" size="lg">
                  Further Reading
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} bg="white" borderRadius="lg">
            <List spacing={3}>
              {Object.values(article.Insights || {}).map((insight, index) => (
                <ListItem key={index}>
                  <Link href={insight.url} isExternal textDecoration="underline">
                    {insight.Title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Courses Section */}
      <Accordion allowToggle defaultIndex={null}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h3" size="lg">
                  Courses
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} bg="white" borderRadius="lg">
            <Text fontSize="sm" fontStyle="italic" mb={3}>
              I only recommend courses that are free, on offer or from an accredited source. Do not buy courses that promise you
              their strategy.
            </Text>
            <List spacing={5}>
              {Object.entries(article.Courses || {}).map(([key, course], index) => (
                <ListItem key={index}>
                  <Link href={course.url} isExternal textDecoration="underline">
                    {course.Name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Legal Considerations Section */}
      <Accordion allowToggle defaultIndex={null}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h3" size="lg">
                  Legal Considerations
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} bg="white" borderRadius="lg">
            <Text fontSize="sm" fontStyle="italic" mb={3}>
              You may want to consider the following legal issues when starting this business.
            </Text>
            <List spacing={5}>
              {Object.entries(article.Legal || {}).map(([key, law], index) => (
                <ListItem key={index}>
                  <Link href={law.legalUrl} isExternal textDecoration="underline">
                    {law.title}
                  </Link>
                  <Text>{law.description}</Text>
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default SidebarContent;
