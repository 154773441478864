// src/Components/VideoPlayer.js
import React from "react";

const VideoPlayer = ({ videoUrl, altText }) => {
  return (
    <video width="80%" height="auto" loop muted controls>
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
