// src/theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Shadows Into Light', cursive`,
    body: `'Poppins', sans-serif`,
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
  },
  colors: {
    primary: "#1d3557",
    secondary: "#a8dadc",
    highlight: "#e63946",
    dark: "#2d2d2d",
  },
  textStyles: {
    h1: {
      fontSize: "4xl",
      fontWeight: "bold",
      color: "primary",
    },
    h2: {
      fontSize: "3xl",
      fontWeight: "semibold",
      color: "primary",
    },
    h3: {
      fontSize: "2xl",
      fontWeight: "semibold",
      color: "primary",
    },
    body: {
      fontSize: "md",
      color: "dark",
    },
    link: {
      color: "highlight",
      textDecoration: "underline",
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: "primary",
      },
    },
    Text: {
      baseStyle: {
        color: "dark",
      },
    },
    Link: {
      baseStyle: {
        color: "highlight",
        textDecoration: "underline",
      },
    },
    Box: {
      baseStyle: {
        padding: "4",
        borderRadius: "md",
      },
    },
    List: {
      baseStyle: {
        spacing: "4",
      },
    },
  },
});

export default theme;
