import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Container,
  Grid,
  GridItem,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  Skeleton,
} from "@chakra-ui/react";
import { getArticleDetail } from "../Services/api";
import ScreenshotCarousel from "../Components/ScreenshotCarousel";
import BreadcrumbsWithButton from "../Components/Breadcrumbs";
import ToolsSection from "../Components/ArticleToolSection";
import SidebarContent from "../Components/ArticleSideBarContent";

const ArticleDetail = () => {
  const { articleId, categoryId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getArticleDetail(articleId)
      .then((articleData) => {
        if (!articleData) {
          setError(true);
        } else {
          setArticle(articleData);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [articleId]);

  const renderParagraphs = (text) => {
    return text.split("||").map((paragraph, idx) => (
      <Text key={idx} mb={6}>
        {paragraph.trim()}
      </Text>
    ));
  };

  if (loading) {
    return (
      <Container maxW="container.xl" py={5}>
        <Skeleton height="40px" mb={6} />
        <Skeleton height="200px" mb={6} />
        <Skeleton height="200px" mb={6} />
        <Skeleton height="400px" mb={6} />
      </Container>
    );
  }

  if (error || !article) {
    return (
      <Box
        minH="400px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="#a8dadc"
        borderRadius="lg"
        textAlign="center"
      >
        <Heading as="h1" size="2xl" color="white">
          Coming Soon
        </Heading>
      </Box>
    );
  }

  return (
    <Container maxW="container.xl" py={5}>
      <BreadcrumbsWithButton
        category={categoryId || "Unknown Category"}
        categoryId={categoryId}
        articleTitle={article.displayTitle || articleId}
        hideButton={true}
      />
      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        <GridItem colSpan={[12, 12, 8]} bg="white" p={5}>
          <Heading as="h1" size="2xl" mb={6}>
            {article.displayTitle || articleId}
          </Heading>
          <Box mb={6} pl={4} borderLeft="4px solid" borderColor="gray.200" fontStyle="italic">
            <Text fontSize="lg" fontWeight="medium" color="#e63946">
              {article.articleIntro}
            </Text>
          </Box>
          <Image src={article.imageUrl} mb={6} borderRadius="lg" loading="lazy" />
          {article.articleContent && renderParagraphs(article.articleContent)}
          <ScreenshotCarousel screenshots={article.articleScreenshots} mb={6} />
          
          <Accordion allowToggle defaultIndex={[0]} mb={6}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Heading as="h3" size="lg">
                      Need Ideas?
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="white" borderRadius="lg">
                <Text fontSize="sm" fontStyle="italic" mb={3}>
                  These are random ideas that I have come up with, there has been no research involved as to the profitability margin or saturation of the market. Please ensure you do your own research before starting any business.
                </Text>
                <List spacing={3} styleType="disc" paddingLeft={4}>
                  {Object.entries(article.Ideas || {}).map(([key, idea], index) => (
                    <ListItem key={index}>
                      <Text fontWeight="bold">{idea.Name}</Text>
                      <Text>{idea.Description}</Text>
                    </ListItem>
                  ))}
                </List>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GridItem>

        <GridItem colSpan={[12, 12, 4]} bg="white" p={5}>
          <SidebarContent article={article} />
        </GridItem>

        <GridItem colSpan={12} bg="white" p={5}>
          <ToolsSection tools={article.tools} articleTitle={article.articleTitle} />
        </GridItem>
      </Grid>
    </Container>
  );
};

export default ArticleDetail;
