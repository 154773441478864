import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Container,
  Button,
  Grid,
  GridItem,
  Image,
  Link,
  Flex,
  Skeleton,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { FaFileExcel, FaFileWord, FaFilePowerpoint } from "react-icons/fa";
import { SiNotion } from "react-icons/si";
import { getResources } from "../Services/api";

const Resources = () => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const fetchedResources = await getResources();
        setResources(fetchedResources);
      } catch (error) {
        console.error("Error fetching resources:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, []);

  const getFileIcon = (fileType, logoUrl) => {
    switch (fileType) {
      case "excel":
        return <FaFileExcel color="green" size="40px" />;
      case "doc":
        return <FaFileWord color="blue" size="40px" />;
      case "ppt":
        return <FaFilePowerpoint color="red" size="40px" />;
      case "notion":
        return <SiNotion size="30px" />;
      case "NA":
        return logoUrl ? (
          <Image
            src={logoUrl}
            alt="Resource Logo"
            boxSize="40px"
            objectFit="cover"
            loading="lazy"
          />
        ) : (
          <Text>No Logo Available</Text>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Container maxW="container.xl" py={10}>
        <Box textAlign="center" mb={10}>
          <Skeleton height="40px" mb={4} />
          <Skeleton height="20px" width="60%" mx="auto" />
        </Box>
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          {[...Array(6)].map((_, index) => (
            <GridItem key={index} colSpan={[12, 6, 4]}>
              <Skeleton height="300px" borderRadius="md" />
            </GridItem>
          ))}
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={10}>
      <Box textAlign="center" mb={10}>
        <Heading as="h1" size="2xl" mb={4}>
          Resources
        </Heading>
        <Text fontSize="lg" color="gray.600">
          Download our free templates to help you get started with your business
          planning.
        </Text>
      </Box>

      <Grid templateColumns="repeat(12, 1fr)" gap={6}>
        {resources.map((resource, index) => (
          <GridItem key={index} colSpan={[12, 6, 4]}>
            <Box
              p={5}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box mb={4} position="relative">
                {getFileIcon(resource.fileType, resource.logoUrl)}
              </Box>
              <Box flex="1" mb={4} textAlign="center">
                <Heading as="h3" size="lg" mb={3}>
                  {resource.title}
                </Heading>
                <Text mb={4} color="gray.700">
                  {resource.description}
                </Text>
              </Box>
              <Flex justifyContent="center">
                <Link href={resource.url} isExternal>
                  <Button
                    rightIcon={<ArrowForwardIcon />}
                    colorScheme="red"
                    variant="solid"
                  >
                    Visit Site
                  </Button>
                </Link>
              </Flex>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
};

export default Resources;
