import React, { useState } from "react";
import {
  Box,
  IconButton,
  Image,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const ScreenshotCarousel = ({ screenshots }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const borderColor = useColorModeValue("gray.800", "white");
  const arrowSize = "24px"; // Larger arrow size for visibility
  const arrowColor = useColorModeValue("red.500", "red.500"); // Enhanced arrow color for visibility

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % screenshots.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (currentIndex - 1 + screenshots.length) % screenshots.length
    );
  };

  return (
    <Flex
      alignItems="center"
      position="relative"
      justify="center"
      mt={4}
      mb={12}
    >
      <IconButton
        icon={<ChevronLeftIcon w={arrowSize} h={arrowSize} />}
        onClick={prevSlide}
        position="absolute"
        left="20px"
        top="50%"
        zIndex="2"
        color={arrowColor}
        backgroundColor="rgba(0, 0, 0, 0.8)" // Adjust opacity as needed
        _hover={{ bg: "whiteAlpha.800", transform: "scale(1.1)" }}
        borderRadius="full"
        boxShadow="lg"
      />

      <Box
        border="2px"
        borderColor={borderColor}
        borderRadius="lg"
        overflow="hidden"
        width="full"
        height="auto"
        boxShadow="2xl"
      >
        <Image src={screenshots[currentIndex]} fit="cover" loading="lazy" />
      </Box>
      <IconButton
        icon={<ChevronRightIcon w={arrowSize} h={arrowSize} />}
        onClick={nextSlide}
        position="absolute"
        right={2} // More to the edge
        top="50%"
        zIndex="2"
        color={arrowColor}
        backgroundColor="rgba(0, 0, 0, 0.8)"
        _hover={{ bg: "whiteAlpha.800", transform: "scale(1.1)" }}
        borderRadius="full"
        boxShadow="dark-lg"
      />
    </Flex>
  );
};

export default ScreenshotCarousel;
