import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import "./terms.css";

const escapeQuotes = (html) => {
  return html
    .replace(/"/g, "&quot;") // Replace double quotes with &quot;
    .replace(/'/g, "&#39;"); // Replace single quotes with &#39;
};

const TermsAndConditions = () => {
  const HTML = `
 <div class=WordSection1>

<p class=MsoNormal><b>TERMS OF USE</b></p>

<p class=MsoNormal><b>Last updated</b>&nbsp;<b>September 24, 2024</b></p>

<p class=MsoNormal><b>AGREEMENT TO OUR LEGAL TERMS</b><a name="_6aa3gkhykvst"></a></p>

<p class=MsoNormal>We are&nbsp;Your Virtual Solutions Ltd, doing business
as&nbsp;The Business Blueprint&nbsp;('<b>Company</b>', '<b>we</b>', '<b>us</b>',
or '<b>our</b>'), a company registered in&nbsp;England&nbsp;at&nbsp;128 City Road,&nbsp;London&nbsp;EC1V
2NX.</p>

<p class=MsoNormal>We operate&nbsp;the
website&nbsp;the-businessblueprint.com&nbsp;(the&nbsp;'<b>Site</b>'), as well
as any other related products and services that refer or link to these legal
terms (the&nbsp;'<b>Legal Terms</b>') (collectively, the&nbsp;'<b>Services</b>').</p>

<p class=MsoNormal><b>We provide guides, resources and information for people
who want to start their own business.</b></p>

<p class=MsoNormal>You can contact us by&nbsp;email
at&nbsp;contactbusinessblueprint@gmail.com&nbsp;or by mail to&nbsp;128 City
Road,&nbsp;London&nbsp;EC1V 2NX,&nbsp;England.</p>

<p class=MsoNormal>These Legal Terms constitute a legally binding agreement
made between you, whether personally or on behalf of an entity ('<b>you</b>'),
and&nbsp;Your Virtual Solutions Ltd, concerning your access to and use of the
Services. You agree that by accessing the Services, you have read, understood,
and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

<p class=MsoNormal>Supplemental terms and conditions or documents that may be
posted on the Services from time to time are hereby expressly incorporated
herein by reference. We reserve the right, in our sole discretion, to make
changes or modifications to these Legal Terms&nbsp;from time to time. We will
alert you about any changes by updating the&nbsp;'Last updated'&nbsp;date of
these Legal Terms, and you waive any right to receive specific notice of each
such change. It is your responsibility to periodically review these Legal Terms
to stay informed of updates. You will be subject to, and will be deemed to have
been made aware of and to have accepted, the changes in any revised Legal Terms
by your continued use of the Services after the date such revised Legal Terms
are posted.</p>

<p class=MsoNormal>The Services are intended for users who are at least 13
years of age. All users who are minors in the jurisdiction in which they reside
(generally under the age of 18) must have the permission of, and be directly
supervised by, their parent or guardian to use the Services. <b>If you are a
minor, you must have your parent or guardian read and agree to these Legal
Terms prior to you using the Services.</b></p>

<p class=MsoNormal>We recommend that you print a copy of these Legal Terms for
your records.</p>

<h1>1. OUR SERVICES</h1>

<p class=MsoNormal>The information provided when using the Services is not
intended for distribution to or use by any person or entity in any jurisdiction
or country where such distribution or use would be contrary to law or
regulation or which would subject us to any registration requirement within
such jurisdiction or country. Accordingly, those persons who choose to access
the Services from other locations do so on their own initiative and are solely
responsible for compliance with local laws, if and to the extent local laws are
applicable.</p>

<p class=MsoNormal>The Services are not tailored to comply with
industry-specific regulations (Health Insurance Portability and Accountability
Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if
your interactions would be subjected to such laws, you may not use the
Services. You may not use the Services in a way that would violate the
Gramm-Leach-Bliley Act (GLBA).</p>

<h1>2. INTELLECTUAL PROPERTY RIGHTS</h1>

<h2>Our intellectual property</h2>

<p class=MsoNormal>We are the owner or the licensee of all intellectual
property rights in our Services, including all source code, databases,
functionality, software, website designs, audio, video, text, photographs, and
graphics in the Services (collectively, the 'Content'), as well as the
trademarks, service marks, and logos contained therein (the 'Marks'). This does
not include third-party trademarks or logos, which are used in accordance with
their respective owners' policies.</p>

<p class=MsoNormal>Our Content and Marks are protected by copyright and
trademark laws (and various other intellectual property rights and unfair
competition laws) and treaties in the United Kingdom, United States and around
the world.</p>

<p class=MsoNormal>The Content and Marks are provided in or through the
Services 'AS IS' for your personal use or internal business purposes only. Any
unauthorized commercial use or distribution of our Content is prohibited unless
expressly authorized by us.</p>

<p class=MsoNormal>&nbsp;</p>

<h2>Your Use of Our Services</h2>

<p class=MsoNormal>Subject to your compliance with these Legal Terms, including
the &quot;PROHIBITED ACTIVITIES&quot; section below, we grant you a
non-exclusive, non-transferable, revocable license to:</p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Access
the Services; and</p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Download
or print a copy of any portion of the Content to which you have properly gained
access,</p>

<p class=MsoNormal>solely for your personal, non-commercial use or internal
business purposes.</p>

<p class=MsoNormal>Except as set out in this section or elsewhere in our Legal
Terms, no part of the Services and no Content or Marks may be copied,
reproduced, aggregated, republished, uploaded, posted, publicly displayed,
encoded, translated, transmitted, distributed, sold, licensed, or otherwise
exploited for any commercial purpose whatsoever, without our express prior
written permission.</p>

<p class=MsoNormal>If you wish to make any use of the Services, Content, or
Marks other than as set out in this section or elsewhere in our Legal Terms,
please contact us at: contactbusinessblueprint@gmail.com. If we grant you
permission to post, reproduce, or publicly display any part of our Services or
Content, you must identify us as the owners or licensors of the Services,
Content, or Marks and ensure that any copyright or proprietary notice appears
on any such posting, reproduction, or display of our Content.</p>

<p class=MsoNormal>We reserve all rights not expressly granted to you in and to
the Services, Content, and Marks.</p>

<p class=MsoNormal>Breach of Intellectual Property Rights</p>

<p class=MsoNormal>Any breach of these Intellectual Property Rights will
constitute a material breach of our Legal Terms and will result in the
immediate termination of your right to use our Services. In addition, we
reserve the right to pursue any legal remedies available for such breach.</p>

<h2>Your Submissions</h2>

<p class=MsoNormal>Please review this section and the &quot;PROHIBITED
ACTIVITIES&quot; section carefully before using our Services to understand (a)
the rights you give us, and (b) the obligations you have when you post or
upload any content through the Services.</p>

<p class=MsoNormal><b>Submissions:</b> By directly sending us any question,
comment, suggestion, idea, feedback, or other information about the Services
(collectively, 'Submissions'), you agree to assign to us all intellectual
property rights in such Submissions. You agree that we shall own these
Submissions and be entitled to their unrestricted use and dissemination for any
lawful purpose, commercial or otherwise, without acknowledgment or compensation
to you.</p>

<p class=MsoNormal><b>Your Responsibility for Submissions</b>: By sending us
Submissions through any part of the Services, you:</p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Confirm
that you have read and agree with our �PROHIBITED ACTIVITIES� section and will
not post, send, publish, upload, or transmit through the Services any
Submission that is illegal, harassing, hateful, harmful, defamatory, obscene,
bullying, abusive, discriminatory, threatening to any person or group, sexually
explicit, false, inaccurate, deceitful, or misleading;</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>To
the extent permissible by applicable law, waive any and all moral rights to any
such Submission;</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Warrant
that any such Submissions are original to you or that you have the necessary
rights and licenses to submit such Submissions and that you have full authority
to grant us the above-mentioned rights in relation to your Submissions; and</p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Warrant
and represent that your Submissions do not contain any confidential
information.</p>

<p class=MsoNormal>You are solely responsible for your Submissions and agree to
indemnify and hold us harmless from any and all losses, liabilities, damages,
claims, or expenses (including legal fees) that we may suffer as a result of
your breach of (a) this section, (b) any third party�s intellectual property
rights, or (c) applicable law.</p>

<h1><a name="_5hg7kgyv9l8z"></a>3.&nbsp;USER REPRESENTATIONS</h1>

<p class=MsoNormal>By using the Services, you represent and warrant that:</p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>You
have the legal capacity and agree to comply with these Legal Terms;</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>You
are not under the age of 13;</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>You
are not a minor in the jurisdiction in which you reside, or if a minor, you
have received parental permission to use the Services;</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>You
will not access the Services through automated or non-human means, whether
through a bot, script, or otherwise;</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>You
will not use the Services for any illegal or unauthorized purpose; and</p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Your
use of the Services will not violate any applicable law or regulation.</p>

<p class=MsoNormal>If you provide any information that is untrue, inaccurate,
not current, or incomplete, we reserve the right to suspend or terminate your
access to the Services and refuse any and all current or future use of the
Services (or any portion thereof).</p>

<h1><a name="_4._PROHIBITED_ACTIVITIES"></a>4.&nbsp;PROHIBITED ACTIVITIES</h1>

<p class=MsoNormal>You may not access or use the Services for any purpose other
than that for which we make the Services available. The Services may not be
used in connection with any commercial endeavours except those that are
specifically endorsed or approved by us (such as authorized affiliate
promotions).<br>
<br>
As a user of the Services, you agree not to:</p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Systematically
retrieve data or other content from the Services to create or compile, directly
or indirectly, a collection, compilation, database, or directory without
written permission from us.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Trick,
defraud, or mislead us and other users, especially in any attempt to learn
sensitive account information such as user passwords.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Circumvent,
disable, or otherwise interfere with security-related features of the Services,
including features that prevent or restrict the use or copying of any Content
or enforce limitations on the use of the Services and/or the Content contained
therein.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Disparage,
tarnish, or otherwise harm, in our opinion, us and/or the Services.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Use
any information obtained from the Services in order to harass, abuse, or harm
another person.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Make
improper use of our support services or submit false reports of abuse or
misconduct.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Use
the Services in a manner inconsistent with any applicable laws or regulations.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Engage
in unauthorized framing of or linking to the Services.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Upload
or transmit (or attempt to upload or transmit) viruses, Trojan horses, or other
material that interferes with any party�s uninterrupted use and enjoyment of
the Services, or that modifies, impairs, disrupts, alters, or interferes with
the use, features, functions, operation, or maintenance of the Services.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Engage in any
automated use of the system, such as using scripts to send comments or
messages, or using any data mining, robots, or similar data gathering and
extraction tools.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Delete the copyright
or other proprietary rights notice from any Content.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Attempt to impersonate
another user or person, or use the username of another user.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>13.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Upload or transmit (or
attempt to upload or transmit) any material that acts as a passive or active
information collection or transmission mechanism, including without limitation
clear graphics interchange formats ('gifs'), 1�1 pixels, web bugs, cookies, or
other similar devices (sometimes referred to as 'spyware' or 'passive
collection mechanisms' or 'pcms').</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>14.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Interfere with,
disrupt, or create an undue burden on the Services or the networks or services
connected to the Services.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>15.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Harass, annoy,
intimidate, or threaten any of our employees or agents engaged in providing any
portion of the Services to you.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>16.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Attempt to bypass any
measures of the Services designed to prevent or restrict access to the
Services, or any portion of the Services.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>17.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Copy or adapt the
Services' software, including but not limited to Flash, PHP, HTML, JavaScript,
or other code.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>18.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Except as permitted by
applicable law, decipher, decompile, disassemble, or reverse engineer any of
the software comprising or in any way making up a part of the Services.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>19.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Except as may be the
result of standard search engine or Internet browser usage, use, launch,
develop, or distribute any automated system, including without limitation any
spider, robot, cheat utility, scraper, or offline reader that accesses the
Services, or use or launch any unauthorized script or other software.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>20.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Use a buying agent or purchasing
agent to make purchases on the Services.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>21.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Make any unauthorized
use of the Services, including collecting usernames and/or email addresses of
users by electronic or other means for the purpose of sending unsolicited
email, or creating user accounts by automated means or under false pretenses.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>22.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Use the Services as
part of any effort to compete with us or create derivative content that
directly competes with the Services. This includes using the Content for any
revenue-generating endeavor or commercial enterprise that involves
republishing, reselling, or distributing our Content without express
permission. You may, however, use the information provided on the Services to
pursue your own business or entrepreneurial ventures (e.g., starting a
print-on-demand business, YouTube channel, e-commerce store, etc.), as long as
it does not involve repurposing or reselling the Services' Content.</p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'>23.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span>Sell or otherwise
transfer your profile or any portion of your access to the Services to another
person.</p>

<h1>5.&nbsp;USER GENERATED CONTRIBUTIONS</h1>

<p class=MsoNormal>While we do not offer users the ability to publicly submit
or post content through the Services, we may provide you with the opportunity
to contact us directly via email or other methods to submit questions,
feedback, suggestions, or other content (collectively,
&quot;Submissions&quot;).</p>

<p class=MsoNormal>By submitting any Submissions to us, you agree to the
following:</p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>You
grant us a non-exclusive, transferable, royalty-free, perpetual, irrevocable,
worldwide license to use, reproduce, modify, publish, distribute, perform,
display, and create derivative works from your Submissions for any purpose,
including but not limited to marketing, advertising, or content creation (e.g.,
videos, blogs, or articles related to this business).</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>We
are under no obligation to maintain any Submission in confidence, to pay any
compensation for any Submission, or to respond to any Submission.</p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>We
may use your Submissions without revealing any personal information, such as
your name, email address, or other identifying details, unless explicitly
agreed upon.</p>

<p class=MsoNormal>You represent and warrant that:</p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Your
Submission is original to you and does not infringe upon the intellectual
property rights of any third party.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>You
have all the necessary rights and permissions to submit the content to us and
to grant us the rights to use it as described.</p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Your
Submission does not violate any applicable law, including laws related to
defamation, privacy, and publicity.</p>

<p class=MsoNormal>Any use of the Services in violation of this section may
result in termination or suspension of your access to the Services.</p>

<h1>6.&nbsp;SUBMISSION&nbsp;LICENCE</h1>

<p class=MsoNormal>By submitting any suggestions, feedback, or other content
regarding the Services directly to us (through email or other direct means),
you agree to the following:</p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><b>Ownership
of Submissions:</b> You acknowledge that we may access, store, process, and use
any information or content you provide in your submissions, including feedback
or suggestions, for any purpose, without compensation to you. This includes,
but is not limited to, using your feedback to improve the Services, creating
new content, or for marketing purposes.</p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><b>No
Ownership Rights:</b> You agree that by submitting suggestions, feedback, or
other materials, you do not retain any ownership or intellectual property
rights in the content of your submissions. We may use your submissions for any
lawful purpose, including reproducing, modifying, and distributing the content,
without any further notice or compensation to you.</p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><b>Personal
Data:</b> Any personal information you provide (such as your name or email
address) will not be shared publicly without your consent. We will use your
personal data in accordance with our Privacy Policy.</p>

<p class=MsoNormal>Liability Disclaimer: We are not liable for any statements,
representations, or claims you make in your submissions. You are solely
responsible for the content of your submissions, and you agree to release us
from any responsibility or legal claims that may arise from your submissions.</p>

<h1>7.&nbsp;THIRD-PARTY WEBSITES AND CONTENT</h1>

<p class=MsoNormal>The Services may contain (or you may be sent via the Site)
links to other websites (&quot;Third-Party Websites&quot;) as well as articles,
photographs, text, graphics, pictures, designs, music, sound, video,
information, applications, software, and other content or items belonging to or
originating from third parties (&quot;Third-Party Content&quot;). Such
Third-Party Websites and Third-Party Content are not investigated, monitored,
or checked for accuracy, appropriateness, or completeness by us, and we are not
responsible for any Third-Party Websites accessed through the Services or any
Third-Party Content posted on, available through, or installed from the
Services, including the content, accuracy, offensiveness, opinions,
reliability, privacy practices, or other policies of or contained in the
Third-Party Websites or the Third-Party Content.</p>

<p class=MsoNormal>Inclusion of, linking to, or permitting the use or
installation of any Third-Party Websites or any Third-Party Content does not
imply approval or endorsement thereof by us. <b>Affiliate Disclosure</b>: Some
of the links to third-party websites on our Services may be <b>affiliate links</b>.
This means that we may earn a commission if you click on or make a purchase
through those links. Our affiliate relationships do not affect the content,
products, or services we recommend, and any transactions made through affiliate
links are exclusively between you and the applicable third party.</p>

<p class=MsoNormal>If you decide to leave the Services and access the
Third-Party Websites or to use or install any Third-Party Content, you do so at
your own risk, and you should be aware these Legal Terms no longer govern. You
should review the applicable terms and policies, including privacy and data
gathering practices, of any website to which you navigate from the Services or
relating to any applications you use or install from the Services.</p>

<p class=MsoNormal>Any purchases you make through Third-Party Websites will be
through other websites and from other companies, and we take no responsibility
whatsoever in relation to such purchases, which are exclusively between you and
the applicable third party. You agree and acknowledge that we do not endorse
the products or services offered on Third-Party Websites, and you shall hold us
blameless from any harm caused by your purchase of such products or services.
Additionally, you shall hold us blameless from any losses sustained by you or
harm caused to you relating to or resulting in any way from any Third-Party
Content or any contact with Third-Party Websites.</p>

<h1>8.&nbsp;SERVICES MANAGEMENT</h1>

<p class=MsoNormal>We reserve the right, but not the obligation, to:</p>

<ol style='margin-top:0cm' start=1 type=1>
 <li class=MsoNormal><b>Monitor the Services</b> for violations of these Legal
     Terms;</li>
 <li class=MsoNormal>Take appropriate legal action against anyone who, in our
     sole discretion, violates the law or these Legal Terms, including, without
     limitation, reporting such user to law enforcement authorities;</li>
 <li class=MsoNormal>In our sole discretion and without limitation, <b>restrict
     access to or limit the availability of the Services</b> for any user who
     violates these Legal Terms or the law;</li>
 <li class=MsoNormal>In our sole discretion and without limitation, notice, or
     liability, <b>remove or disable any files or content</b> that are
     excessive in size or place an undue burden on our systems; and</li>
 <li class=MsoNormal>Otherwise manage the Services in a manner designed to
     protect our rights and property and to facilitate the proper functioning
     of the Services.</li>
</ol>

<h1>9.&nbsp;PRIVACY POLICY</h1>

<p class=MsoNormal>We care about data privacy and security. By using the
Services, you agree to be bound by our Privacy Policy, which is incorporated
into these Legal Terms. The Privacy Policy explains how we collect, use, and
protect your information when you access or use our Services. Please review our
Privacy Policy to understand our practices.</p>

<p class=MsoNormal>Please note that the Services are hosted in the United
Kingdom and the United States. By continuing to use the Services from other
regions with laws governing personal data collection, use, or disclosure that
differ from the laws in the United Kingdom and the United States, you consent
to the transfer and processing of your data in the United Kingdom and United
States.</p>

<p class=MsoNormal>Additionally, we comply with the U.S. Children�s Online
Privacy Protection Act (COPPA) and do not knowingly collect or solicit personal
information from children under the age of 13. If we learn that we have
received such information without verified parental consent, we will promptly
delete that information.</p>

<h1>10.&nbsp;TERM AND TERMINATION</h1>

<p class=MsoNormal>These Legal Terms shall remain in full force and effect
while you use the Services.</p>

<p class=MsoNormal><b>WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS</b>,
WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN
IP ADDRESSES) TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION.</p>

<p class=MsoNormal>We may terminate your access to the Services at any time,
without warning, in our sole discretion.</p>

<p class=MsoNormal>In addition to terminating or suspending your access, we
reserve the right to take appropriate legal action, including, without
limitation, pursuing <b>civil</b>, <b>criminal</b>, or <b>injunctive</b>
redress.</p>

<h1>11.&nbsp;MODIFICATIONS AND INTERRUPTIONS</h1>

<p class=MsoNormal>We reserve the right to <b>change, modify, or remove</b> the
contents of the Services at any time, for any reason, and at our sole
discretion, without notice. However, we have no obligation to update any
information on our Services. We will not be liable to you or any third party
for any modification, price change, suspension, or discontinuance of the
Services.</p>

<p class=MsoNormal>We cannot guarantee that the Services will be available at
all times. From time to time, we may experience <b>hardware, software</b>, or
other problems, or need to perform maintenance related to the Services,
resulting in <b>interruptions, delays, or errors</b>. We reserve the right to
change, revise, update, suspend, discontinue, or otherwise modify the Services
at any time or for any reason without notice to you.</p>

<p class=MsoNormal>You agree that we have no liability whatsoever for any <b>loss,
damage, or inconvenience</b> caused by your inability to access or use the
Services during any downtime or discontinuance of the Services. Nothing in
these Legal Terms will be construed to obligate us to maintain and support the
Services or to supply any corrections, updates, or releases in connection with
the Services.</p>

<h1>12.&nbsp;GOVERNING LAW</h1>

<p class=MsoNormal>These Legal Terms are governed by and interpreted following
the laws of the <b>United Kingdom</b>, and the use of the United Nations
Convention of Contracts for the International Sale of Goods is expressly
excluded.</p>

<p class=MsoNormal>If your habitual residence is in the <b>European Union</b>
and you are a consumer, you additionally possess the protection provided to you
by the obligatory provisions of the law in your country of residence.</p>

<p class=MsoNormal>Both <b>Your Virtual Solutions Ltd</b> and yourself agree to
submit to the <b>non-exclusive jurisdiction</b> of the courts of <b>England and
Wales</b>, which means that you may bring a claim to enforce your consumer
protection rights in regard to these Legal Terms in the United Kingdom or in
the <b>EU country</b> in which you reside.</p>

<h1>13.&nbsp;DISPUTE RESOLUTION</h1>

<p class=MsoNormal>If you are a consumer residing in the <b>European Union</b>,
you have the right to resolve disputes with us using the European Commission's <b>Online
Dispute Resolution (ODR)</b> platform. The ODR platform is an official website
managed by the European Commission dedicated to helping consumers and traders
resolve their disputes out of court. You can access the ODR platform at <a
href="https://ec.europa.eu/consumers/odr" target="_new"><b>https://ec.europa.eu/consumers/odr</b></a>.</p>

<p class=MsoNormal>If you have any concerns or disputes related to the Services
and would like to bring them to our attention, please contact us at <b>contactbusinessblueprint@gmail.com</b>,
and we will do our best to address your concerns promptly.</p>

<h1>14.&nbsp;CORRECTIONS</h1>

<p class=MsoNormal>There may be information on the Services that contains
typographical errors, inaccuracies, or omissions, including descriptions,
pricing, availability, and other information. We reserve the right to correct
any errors, inaccuracies, or omissions, and to change or update the information
on the Services at any time, without prior notice.</p>

<h1>15.&nbsp;DISCLAIMER</h1>

<p class=MsoNormal>The Services are provided on an <b>as-is</b> and <b>as-available</b>
basis. You agree that your use of the Services is at your <b>sole risk</b>. To
the fullest extent permitted by law, we disclaim all warranties, express or
implied, in connection with the Services and your use thereof, including,
without limitation, the implied warranties of <b>merchantability</b>, <b>fitness
for a particular purpose</b>, and <b>non-infringement</b>.</p>

<p class=MsoNormal>We make no warranties or representations regarding the <b>accuracy</b>
or <b>completeness</b> of the content on the Services or the content of any
websites or mobile applications linked to the Services, and we assume no
liability for any:</p>

<ol style='margin-top:0cm' start=1 type=1>
 <li class=MsoNormal>Errors, mistakes, or inaccuracies in the content and
     materials;</li>
 <li class=MsoNormal>Personal injury or property damage resulting from your
     access to and use of the Services;</li>
 <li class=MsoNormal>Unauthorized access to or use of our secure servers and/or
     any personal or financial information stored therein;</li>
 <li class=MsoNormal>Interruption or cessation of transmission to or from the
     Services;</li>
 <li class=MsoNormal>Bugs, viruses, Trojan horses, or similar threats that may
     be transmitted to or through the Services by any third party; and/or</li>
 <li class=MsoNormal>Errors or omissions in any content or materials, or any
     loss or damage incurred as a result of using any content posted,
     transmitted, or made available via the Services.</li>
</ol>

<p class=MsoNormal>We do not warrant, endorse, guarantee, or assume
responsibility for any product or service advertised or offered by a third
party through the Services, any hyperlinked website, or any website or mobile
application featured in any banner or other advertising, and we will not be a
party to or responsible for monitoring any transaction between you and any
third-party providers of products or services. As with the purchase of a
product or service through any medium or environment, you should use your best
judgment and exercise caution where appropriate.</p>

<h1>16.&nbsp;LIMITATIONS OF LIABILITY</h1>

<p class=MsoNormal>To the fullest extent permitted by law, in no event will we
or our directors, employees, or agents be liable to you or any third party for
any <b>direct, indirect, consequential, exemplary, incidental, special, or
punitive damages</b>, including lost profits, lost revenue, loss of data, or
other damages arising from your use of the Services, even if we have been
advised of the possibility of such damages.</p>

<p class=MsoNormal><b>Notwithstanding anything to the contrary contained
herein, our liability to you for any cause whatsoever and regardless of the
form of the action will at all times be limited to a maximum of �1,000.</b></p>

<p class=MsoNormal>Certain laws, including <b>US state laws</b> and <b>international
laws</b>, do not allow limitations on implied warranties or the exclusion or
limitation of certain damages. If these laws apply to you, some or all of the
above disclaimers or limitations may not apply, and you may have additional
rights.</p>

<h1>17.&nbsp;INDEMNIFICATION</h1>

<p class=MsoNormal>You agree to defend, indemnify, and hold us harmless,
including our subsidiaries, affiliates, and all of our respective officers,
agents, partners, and employees, from and against any <b>loss, damage,
liability, claim, or demand</b>, including reasonable attorneys� fees and
expenses, made by any third party due to or arising out of:</p>

<ol style='margin-top:0cm' start=1 type=1>
 <li class=MsoNormal>Your use of the Services;</li>
 <li class=MsoNormal>Your breach of these Legal Terms;</li>
 <li class=MsoNormal>Any breach of your representations and warranties set
     forth in these Legal Terms;</li>
 <li class=MsoNormal>Your violation of the rights of a third party, including
     but not limited to intellectual property rights; or</li>
 <li class=MsoNormal>Any overt harmful act toward any other user of the
     Services with whom you connected via the Services.</li>
</ol>

<p class=MsoNormal><b>Notwithstanding the foregoing</b>, we reserve the right,
at your expense, to assume the exclusive defense and control of any matter for
which you are required to indemnify us, and you agree to cooperate, at your
expense, with our defense of such claims. We will use reasonable efforts to
notify you of any such claim, action, or proceeding that is subject to this
indemnification upon becoming aware of it.</p>

<h1>18.&nbsp;USER DATA</h1>

<p class=MsoNormal>We may maintain certain data that you transmit to the
Services for the purpose of managing the performance of the Services, as well
as data relating to your use of the Services (such as cookies or analytics
data). Although we perform regular routine backups of data, you are solely
responsible for any data that you transmit or that relates to any activity you
have undertaken using the Services.</p>

<p class=MsoNormal>You agree that we shall have no liability to you for any <b>loss</b>
or <b>corruption</b> of such data, and you hereby waive any right of action
against us arising from any such loss or corruption.</p>

<h1>19.&nbsp;ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h1>

<p class=MsoNormal>Visiting the Services, sending us emails, and completing
online forms constitute <b>electronic communications</b>. You consent to
receive electronic communications, and you agree that all agreements, notices,
disclosures, and other communications we provide to you electronically�via
email and on the Services�satisfy any legal requirement that such communication
be in writing.</p>

<p class=MsoNormal>You agree to the use of <b>electronic signatures</b>,
contracts, orders, and other records, and to the <b>electronic delivery</b> of
notices, policies, and records of transactions initiated or completed by us or
via the Services. You hereby waive any rights or requirements under any
statutes, regulations, rules, ordinances, or other laws in any jurisdiction
which require an original signature, non-electronic delivery, or retention of
non-electronic records, or to payments or granting of credits by any means
other than electronic means.</p>

<h1>20.&nbsp;CALIFORNIA USERS AND RESIDENTS</h1>

<p class=MsoNormal>If any complaint with us is not satisfactorily resolved, you
may contact the Complaint Assistance Unit of the Division of Consumer Services
of the <b>California Department of Consumer Affairs</b> in writing at <b>1625
North Market Blvd., Suite N 112, Sacramento, California 95834</b>, or by
telephone at <b>(800) 952-5210</b> or <b>(916) 445-1254</b>.</p>

<h1>21.&nbsp;MISCELLANEOUS</h1>

<p class=MsoNormal>These Legal Terms, along with any policies or operating
rules posted by us on the Services, constitute the <b>entire agreement</b> and
understanding between you and us. Our failure to exercise or enforce any right
or provision of these Legal Terms shall not operate as a waiver of such right
or provision.</p>

<p class=MsoNormal>These Legal Terms operate to the fullest extent permissible
by law. We may assign any or all of our rights and obligations to others at any
time. We are not responsible or liable for any loss, damage, delay, or failure
to act caused by any cause beyond our reasonable control.</p>

<p class=MsoNormal>If any provision or part of a provision of these Legal Terms
is determined to be <b>unlawful, void, or unenforceable</b>, that provision or
part of the provision is deemed severable from these Legal Terms and does not
affect the validity and enforceability of any remaining provisions.</p>

<p class=MsoNormal>No <b>joint venture</b>, <b>partnership</b>, <b>employment</b>,
or <b>agency relationship</b> is created between you and us as a result of
these Legal Terms or your use of the Services. You agree that these Legal Terms
will not be construed against us by virtue of having drafted them. You hereby
waive any and all defenses you may have based on the <b>electronic form</b> of
these Legal Terms and the lack of signing by the parties to execute these Legal
Terms.</p>

<h1>22.&nbsp;CONTACT US</h1>

<p class=MsoNormal>In order to resolve a complaint regarding the Services or to
receive further information regarding use of the Services, please contact us
at:</p>

<p class=MsoNormal><b>Your Virtual Solutions Ltd</b></p>

<p class=MsoNormal><b>128 City Road</b></p>

<p class=MsoNormal><b>London&nbsp;EC1V 2NX</b></p>

<p class=MsoNormal><b>England</b></p>

<p class=MsoNormal><b>&nbsp;</b>Or email:</p>

<p class=MsoNormal><b>contactbusinessblueprint@gmail.com</b></p>

<p class=MsoNormal>&nbsp;</p>

</div>
 
 `;

  const escapedHTML = escapeQuotes(HTML);
  return (
    <div className="terms-container">
      <Box p={10}>
        <Heading as="h1" size="xl" mb={6}>
          Terms and Conditions
        </Heading>
        <Box dangerouslySetInnerHTML={{ __html: escapedHTML }} />
      </Box>
    </div>
  );
};

export default TermsAndConditions;
