import React, { useState, useEffect } from "react";
import { Box, Heading, Text, Image, Skeleton, Container, Link } from "@chakra-ui/react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { getBlogPostBySlug } from "../Services/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ArrowBackIcon } from "@chakra-ui/icons";

const BlogPost = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        console.log("Fetching blog post with slug:", slug);
        const blogPost = await getBlogPostBySlug(slug);
        console.log("Fetched blog post:", blogPost);
        setBlog(blogPost);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [slug]);

  if (loading) {
    return (
      <>
        <Skeleton height="40px" mb={4} />
        <Skeleton height="200px" />
        <Skeleton height="200px" mt={4} />
      </>
    );
  }

  if (!blog) {
    return <Text>Blog post not found.</Text>;
  }

  const options = {
    renderNode: {
      "paragraph": (node, children) => (
        <Box as="p" mb={4}>
          {children}
        </Box>
      ),
      "heading-1": (node, children) => (
        <Heading as="h1" size="2xl" mb={6}>
          {children}
        </Heading>
      ),
      "heading-2": (node, children) => (
        <Heading as="h2" size="xl" mb={5}>
          {children}
        </Heading>
      ),
      "heading-3": (node, children) => (
        <Heading as="h3" size="lg" mb={4}>
          {children}
        </Heading>
      ),
      "unordered-list": (node, children) => (
        <Box as="ul" mb={4} pl={6}>
          {children}
        </Box>
      ),
      "ordered-list": (node, children) => (
        <Box as="ol" mb={4} pl={6}>
          {children}
        </Box>
      ),
      "list-item": (node, children) => (
        <Box as="li" mb={2}>
          {children}
        </Box>
      ),
    },
  };

  return (
    <Container maxW="container.xl" py={5}>
      {/* Back to Blog Button */}
      <Link
        as={RouterLink}
        to="/blog"
        display="flex"
        alignItems="center"
        mb={4}
        fontSize="lg"
        color="teal.500"
        _hover={{ textDecoration: "none", color: "teal.700" }}
      >
        <ArrowBackIcon mr={2} />
        Back to Blog
      </Link>
      
      {/* Blog Title */}
      <Heading as="h1" size="xl" mb={4}>
        {blog.fields.title}
      </Heading>
      
      {/* Featured Image */}
      {blog.fields.featuredImage && (
        <Image
          src={blog.fields.featuredImage.fields.file.url}
          alt={blog.fields.title}
          mb={6}
          borderRadius="lg"
          width="100%"
          height={["200px", "300px", "400px"]}
          objectFit="cover"
        />
      )}

      {/* Blog Body */}
      <Box>
        {documentToReactComponents(blog.fields.body, options)}
      </Box>
    </Container>
  );
};

export default BlogPost;
