// src/components/Navigation.js
import React from "react";
import { VStack, Button, Flex } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

const Navigation = ({ onClose }) => {
  const location = useLocation();

  return (
    <Flex height="100%" align="center" justify="center" width="100%">
      <VStack spacing={5} align="center">
        <Button
          as={Link}
          to="/categories"
          onClick={onClose}
          variant="ghost"
          bg={location.pathname === "/categories" ? "#e63946" : "transparent"}
          color={location.pathname === "/categories" ? "white" : "black"}
          width="200px"
          _hover={{ bg: "#e2e8f0", color: "black" }} // Hover color settings
        >
          Categories
        </Button>
        <Button
          as={Link}
          to="/AllTools"
          onClick={onClose}
          variant="ghost"
          bg={location.pathname === "/AllTools" ? "#e63946" : "transparent"}
          color={location.pathname === "/AllTools" ? "white" : "black"}
          width="200px"
          _hover={{ bg: "#e2e8f0", color: "black" }} // Hover color settings
        >
          All Tools
        </Button>
        <Button
          as={Link}
          to="/blog"
          onClick={onClose}
          variant="ghost"
          bg={location.pathname === "/blog" ? "#e63946" : "transparent"}
          color={location.pathname === "/blog" ? "white" : "black"}
          width="200px"
          _hover={{ bg: "#e2e8f0", color: "black" }} // Hover color settings
        >
          Blog
        </Button>
        <Button
          as={Link}
          to="/resources"
          onClick={onClose}
          variant="ghost"
          bg={location.pathname === "/resources" ? "#e63946" : "transparent"}
          color={location.pathname === "/resources" ? "white" : "black"}
          width="200px"
          _hover={{ bg: "#e2e8f0", color: "black" }} // Hover color settings
        >
          Resources
        </Button>
        <Button
          as={Link}
          to="/about"
          onClick={onClose}
          variant="ghost"
          bg={location.pathname === "/about" ? "#e63946" : "transparent"}
          color={location.pathname === "/about" ? "white" : "black"}
          width="200px"
          _hover={{ bg: "#e2e8f0", color: "black" }} // Hover color settings
        >
          About
        </Button>
      </VStack>
    </Flex>
  );
};

export default Navigation;
