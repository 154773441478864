import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Text, Box, SimpleGrid, Heading, Skeleton, Image, Flex } from "@chakra-ui/react";
import { getArticleCategories } from "../Services/api";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getArticleCategories()
      .then((response) => {
        setCategories(response);
      })
      .catch((error) => {
        setError("Error fetching categories.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const formatCategoryType = (type) => {
    return type
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  if (loading) {
    return (
      <Box p={5}>
        <Skeleton height="40px" mb={6} />
        <SimpleGrid columns={[1, 2, 3]} spacing={10}>
          {[...Array(6)].map((_, index) => (
            <Skeleton key={index} height="250px" borderRadius="lg" />
          ))}
        </SimpleGrid>
      </Box>
    );
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Box p={5} bg="#a8dadc" borderRadius="lg" minHeight="500px">
      <Heading as="h1" size="2xl" mb={6} textAlign="center">
        Choose a business type:
      </Heading>
      <Flex justifyContent="center">
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={10}
          width="100%"
        >
          {categories.map((category) => (
            <Box
              key={category.id}
              borderRadius="md"
              overflow="hidden"
              height={{ base: "150px", md: "200px", lg: "250px" }}
              width="100%"
              maxW={{ base: "100%", md: "300px", lg: "350px" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              bg="white"
              _hover={{
                transform: "scale(1.05)",
                transition: "all 0.3s ease-in-out",
              }}
              m={{ base: "10px auto", md: "20px auto", lg: "30px auto" }}
              as={Link}
              to={`/categories/${category.id}/articles`}
            >
              {category.imageUrl ? (
                <Image
                  src={category.imageUrl}
                  alt={category.type}
                  fit="cover"
                  height="100%"
                  width="100%"
                  loading="lazy"
                />
              ) : (
                <Box
                  bg="gray.200"
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text>No Image Available</Text>
                </Box>
              )}
              <Text
                position="absolute"
                color="white"
                fontWeight="bold"
                fontSize="xl"
                bg="rgba(0, 0, 0, 0.5)"
                p={2}
                borderRadius="md"
                textAlign="center"
              >
                {formatCategoryType(category.type)}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default Categories;
