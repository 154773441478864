import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

// Function to get categories
export const getArticleCategories = async () => {
  const categoriesCollection = collection(db, "categories");
  const categorySnapshot = await getDocs(categoriesCollection);
  const categoryList = categorySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return categoryList;
};

// Function to get published articles
export const getPublishedArticles = async () => {
  const articlesRef = collection(db, "articles");
  const q = query(articlesRef, where("status", "==", "Published"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

// Function to get coming soon articles
export const getComingSoonArticles = async () => {
  const articlesRef = collection(db, "articles");
  const q = query(articlesRef, where("status", "==", "Coming Soon"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

// Function to get articles
export const getArticles = async () => {
  const articlesCollection = collection(db, "articles");
  const articleSnapshot = await getDocs(articlesCollection);
  const articleList = articleSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return articleList;
};

// Function to get a single article detail
export const getArticleDetail = async (articleId) => {
  try {
    const articleRef = doc(db, "articles", articleId);
    const articleSnap = await getDoc(articleRef);

    if (articleSnap.exists()) {
      return { id: articleSnap.id, ...articleSnap.data() };
    } else {
      throw new Error("No such document!");
    }
  } catch (error) {
    console.error("Error fetching article details:", error);
    throw error;
  }
};

// Function to get a single blueprint detail
export const getBlueprintDetail = async (articleId) => {
  try {
    const blueprintRef = doc(db, "businessModels", articleId); // Fetch from the "businessModels" collection
    const blueprintSnap = await getDoc(blueprintRef);

    if (blueprintSnap.exists()) {
      return blueprintSnap.data(); // Return the blueprint document data
    } else {
      throw new Error("No blueprint found!");
    }
  } catch (error) {
    console.error("Error fetching blueprint details:", error);
    throw error;
  }
};

export const getTools = async () => {
  try {
    const toolsCollection = collection(db, "toolsCollection");
    const toolsSnapshot = await getDocs(toolsCollection);
    const toolsList = toolsSnapshot.docs.map((doc) => {
      const toolData = { id: doc.id, ...doc.data() };
      
      return toolData;
    });

    return toolsList;
  } catch (error) {
    console.error("Error fetching tools from Firebase:", error);
  }
};




export const getResources = async () => {
  const resourcesCollection = collection(db, "resources");
  const resourcesSnapshot = await getDocs(resourcesCollection);
  const resourcesList = resourcesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return resourcesList;
};

export const getToolReviewDetail = async (toolName) => {
  try {
    if (!toolName) {
      throw new Error("Tool Name is undefined or invalid");
    }

    // Fetch document by toolName as the document ID
    const toolReviewRef = doc(db, "toolsCollection", toolName);
    const toolReviewDoc = await getDoc(toolReviewRef);

    // Log the document ID from Firestore
    console.log(`Firestore document ID: ${toolReviewDoc.id}, Expected tool name: ${toolName}`);

    // Check if document exists
    if (!toolReviewDoc.exists()) {
      throw new Error(`No document found for tool: ${toolName}`);
    }

    return toolReviewDoc.data(); // Return the document data
  } catch (error) {
    console.error(`Error fetching review for tool ${toolName}:`, error);
    throw error;
  }
};



export const getToolsByTag = async (tag) => {
  const toolsRef = collection(db, "toolsCollection");
  const q = query(toolsRef, where("tags", "array-contains", tag));
  const querySnapshot = await getDocs(q);

  const toolsList = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return toolsList;
};
