import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LayoutOuter from "./Components/LayoutOuter";
import LayoutInner from "./Components/LayoutInner";
import Categories from "./Pages/Categories";
import Directory from "./Pages/Directory";
import About from "./Pages/About";
import Home from "./Pages/Home";
import Articles from "./Pages/Articles";
import ArticleDetail from "./Pages/ArticleDetail";
import Blueprint from "./Pages/Blueprint";
import Resources from "./Pages/Resources";
import ToolReview from "./Pages/ToolReview";
import Footer from "./Components/Footer";
import ComingSoon from "./Components/ComingSoon";
import TermsAndConditions from "./Components/TermsAndConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import CookieConsent from "react-cookie-consent";
import BlogPost from "./Pages/BlogPost";
import BlogListing from "./Pages/BlogListing";
import "./App.css";

function App() {
  const initializeGoogleAnalytics = () => {
    // Initialize Google Analytics if consent is given
    console.log("Google Analytics initialized!");
  };
  return (
    <Router>
      <LayoutOuter>
        <LayoutInner>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/:categoryId" element={<Articles />} />
            <Route path="/AllTools" element={<Directory />} />
            <Route path="/about" element={<About />} />{" "}
            <Route path="/resources" element={<Resources />} />
            <Route
              path="/categories/:categoryId/articles"
              element={<Articles />}
            />
            <Route
              path="/categories/:categoryId/articles/:articleId"
              element={<ArticleDetail />}
            />
            <Route
              path="/categories/:categoryId/blueprint/:articleId"
              element={<Blueprint />}
            />
            <Route path="/tool-review/:toolId" element={<ToolReview />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/footer" element={<Footer />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blog" element={<BlogListing />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
          </Routes>
        </LayoutInner>
      </LayoutOuter>
      <CookieConsent
        location="bottom"
        buttonText="Accept Cookies"
        declineButtonText="Decline Cookies"
        enableDeclineButton
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        declineButtonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
       onAccept={() => {
        initializeGoogleAnalytics();
      }
    }>
          This website uses cookies to enhance the user experience.{" "}
        <a href="/privacy-policy" style={{ color: "#fff" }}>
          Learn more
        </a>
      </CookieConsent> 

    </Router>
  );
}

export default App;
